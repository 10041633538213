import Login from 'modules/auth/login';

export default Login;

// export function getStaticProps() {
//   return {
//     redirect: {
//       destination: '/maintenance',
//     },
//   };
// }
