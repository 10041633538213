/* eslint-disable react/no-unknown-property */
import Config from 'common/config';
import { useAuthorization, AuthType } from 'hooks/use-authorization';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';

import LoginCard from './login-card';

function WrapperComponent(props) {
  useAuthorization({
    type: AuthType.UnAuthenticated,
    hasRedirect: !!props.redirect,
  });
  return <LoginCard redirect={props.redirect} />;
}

export default function Login() {
  const [redirect, setRedirect] = React.useState<any>();
  const { locale } = useRouter();
  const { t } = useTranslation();
  React.useEffect(() => {
    try {
      if (sessionStorage && sessionStorage.getItem('prevUrl')) {
        setRedirect(sessionStorage.getItem('prevUrl'));
      }
    } catch (e) {
    } finally {
    }
  }, []);

  return (
    <>
      <style jsx global>{`
        html,
        body,
        #root,
        #__next {
          height: 100%;
        }
        body {
          overflow: auto !important;
        }
      `}</style>
      <NextSeo
        title={t('common:title_login')}
        description={t('common:description_login')}
        canonical={Config.apiEndpoint + '/login'}
        openGraph={{
          type: 'website',
          locale,
          url: Config.apiEndpoint + '/login',
          title: t('common:title_login'),
          description: t('common:description_login'),
        }}
      />
      <>
        <WrapperComponent redirect={redirect} />
      </>
    </>
  );
}
