import { useLogin } from 'api-hooks/auth/mutation';
import { getMeKey, useGetMe } from 'api-hooks/auth/query';
import NavigationEnum from 'common/navigation';
import { queryClient } from 'common/repositories/query-client';
import Stack from 'components/commons/stack';
import Text from 'components/commons/text';
import Input from 'components/elements/field';
import Form from 'components/elements/form';
import useYupValidationResolver from 'hooks/use-yup-validation-resolver';
import Link from 'next/link';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import * as Yup from 'yup';

import { ALinkMenu, CardContainer, styles } from '../style';

type FormType = {
  username: string;
  password: string;
};

interface Props {
  redirect?: string;
}

export default function LoginCard(props: Props) {
  const { t } = useTranslation();
  const router = useRouter();
  const toast = useToasts();
  const { mutateAsync } = useLogin();

  const enabled = React.useMemo(() => {
    try {
      return !!localStorage?.getItem('credential');
    } catch (e) {
      return false;
    } finally {
    }
  }, []);

  const { refetch } = useGetMe({
    enabled,
  });

  const yupSchema = React.useMemo(
    () =>
      Yup.object()
        .shape({
          username: Yup.string().email().required(),
          password: Yup.string().min(8).required(),
        })
        .required(),
    [],
  );

  const resolver = useYupValidationResolver(yupSchema);
  const methods = useForm<FormType>({
    defaultValues: {
      username: '',
      password: '',
    },
    resolver,
    mode: 'all',
  });
  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const result = await mutateAsync(values, {
          onSuccess: (data) => {
            queryClient.setQueryData(getMeKey(), data.data);
          },
        });

        localStorage.setItem('credential', JSON.stringify(result.data));

        refetch();
        global.refreshToken = result?.data?.refreshToken;

        router.push(props.redirect || NavigationEnum.home);
        if (props.redirect) {
          sessionStorage?.setItem('prevUrl', '');
        }
      } catch (error) {
        toast.addToast(t('common:invalid_credential'), {
          appearance: 'error',
          autoDismiss: true,
        });
      } finally {
      }
    },
    [mutateAsync, refetch, router, props.redirect, toast, t],
  );

  const linkStyles: React.CSSProperties = {
    textDecoration: 'none',
  };

  return (
    <CardContainer>
      <Text variant="headline3" weight="bold" align="center" css={{ mb: 16 }}>
        {t('common:login_account')}
      </Text>
      <Text variant="body3" css={{ mb: 40 }} align="center">
        {t('common:login_account_message')}
      </Text>
      <Form methods={methods} onSubmit={onSubmit} autoComplete="off">
        <Stack gap="xl">
          <Stack.Item css={styles.inputCss}>
            <Input
              type="email"
              name="username"
              label={t('common:email_address')}
              placeholder={t('common:input_email_address')}
              autoComplete="email-off"
            />
          </Stack.Item>
          <Stack.Item css={styles.inputCss}>
            <Stack>
              <Stack.Item>
                <Input
                  type="password"
                  name="password"
                  label={t('common:password')}
                  placeholder={t('common:input_password')}
                  autoComplete="password-off"
                />
              </Stack.Item>
              <Stack.Item alignItems="end">
                <Link
                  passHref
                  href={NavigationEnum.forgotPassword}
                  style={linkStyles}
                >
                  <ALinkMenu>{t('common:forgot_password')}?</ALinkMenu>
                </Link>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item css={styles.inputCss}>
            <Stack rootCss={{ mt: 24 }}>
              <Stack.Item>
                <Input
                  type="submit"
                  text={t('common:login')}
                  css={{ width: '100%' }}
                />
              </Stack.Item>
              <Stack.Item css={{ mt: 24 }}>
                <Stack
                  direction="horizontal"
                  justify="center"
                  alignItems="center"
                  wrap
                >
                  <Text>{t('common:dont_have_an_account_yet')}</Text>
                  <Stack.Item css={{ ml: 8 }}>
                    <Link
                      href={NavigationEnum.register}
                      passHref
                      style={linkStyles}
                    >
                      <ALinkMenu>{t('common:sign_up_now')}</ALinkMenu>
                    </Link>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </Form>
    </CardContainer>
  );
}
