import NavigationEnum from 'common/navigation';
import Router from 'next/router';

import useGetMeQuery from './use-get-me-query';

export enum AuthType {
  Authenticated = 'authenticated',
  UnAuthenticated = 'unAuthenticated',
}

interface Props {
  type: AuthType;
  hasRedirect?: boolean;
}

export function useAuthorization(props: Props) {
  const { type, hasRedirect } = props;
  const { isAuthenticated, isAuthenticating } = useGetMeQuery();

  const redirectToLogin =
    type === AuthType.Authenticated && isAuthenticated === false;
  const redirectToHome = type === AuthType.UnAuthenticated && isAuthenticated;

  if (typeof window === 'undefined' || isAuthenticating) {
    return;
  }
  if (redirectToLogin) {
    Router.push(NavigationEnum.login);
  } else if (redirectToHome && !hasRedirect) {
    Router.push(NavigationEnum.home);
  }
}
